@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500;600;700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Inter:wght@100..900&display=swap");

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.no-scrollbar {
  -ms-overflow-style: none; /* for IE and Edge */
  scrollbar-width: none; /* for Firefox */
}

.custom-button {
  flex-shrink: 0;
  border-radius: 76px;
  border: 3px solid rgba(252, 242, 207, 0.44);
  background: linear-gradient(
    307deg,
    #4f7b17 8.66%,
    #3c5913 13.9%,
    #2f4010 20.58%,
    #1c210b 31.55%,
    #110f08 57.29%,
    #4d4526 80.18%,
    #877945 95.44%,
    #f0d87c 104.02%
  );
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

@keyframes moveInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.moveInUp {
  animation-name: moveInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.delay1 {
  animation-delay: 0s;
}
.delay2 {
  animation-delay: 0.5s;
}
.delay3 {
  animation-delay: 1s;
}
.delay4 {
  animation-delay: 1.5s;
}

.delay5 {
  animation-delay: 2s;
}
